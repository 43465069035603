import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import Layout from "../components/_App/layout"
import Navbar from "../components/_App/Navbar"
import Footer from "../components/_App/Footer"

const query = graphql`
  {
    strapiPageNotFound {
      title
      shortText
      btnText
      btnLink
    }
  }
`

const NotFoundPage = () => {
  const data = useStaticQuery(query)
  const {
    strapiPageNotFound: { title, shortText, btnText, btnLink },
  } = data

  return (
    <Layout>
      <Navbar pageTitle={title} />

      <div className="error-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="error-content">
                <div className="notfound-404">
                  <h2>404</h2>
                </div>
                <h3>{shortText}</h3>
                <Link to={btnLink} className="btn btn-primary">
                  {btnText}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default NotFoundPage
